import api from '@/utils/request'

// 获取用户配音秀 
export function getDubShowVOPage(params) {
  return api({
    method: 'get',
    url: '/dubShow/getDubShowVOPage',
    params,
  })
}


export function AndroidDownload(params) {
  return api({
    method: 'GET',
    url: '/app/version/getChannelVersion',
    params,
  })
}
